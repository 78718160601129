// extracted by mini-css-extract-plugin
export var buttonContainer = "home-internet-checkout-module--buttonContainer--0b12a";
export var checkboxInput = "home-internet-checkout-module--checkboxInput--26636";
export var closeButtonContainer = "home-internet-checkout-module--closeButtonContainer--f4f6b";
export var consentPopUpContainer = "home-internet-checkout-module--consentPopUpContainer--9a096";
export var consentPopUpContent = "home-internet-checkout-module--consentPopUpContent--cceb3";
export var consentPopUpContentContainer = "home-internet-checkout-module--consentPopUpContentContainer--b4c99";
export var detailHeading = "home-internet-checkout-module--detailHeading--95420";
export var detailsSection = "home-internet-checkout-module--detailsSection--37016";
export var discountEligibleInfo = "home-internet-checkout-module--discountEligibleInfo--7ca73";
export var discountInfo = "home-internet-checkout-module--discountInfo--67a08";
export var discountTitle = "home-internet-checkout-module--discountTitle--8e62d";
export var discountTitleBold = "home-internet-checkout-module--discountTitleBold--d43a0";
export var existingCustomer = "home-internet-checkout-module--existingCustomer--f622d";
export var fillInfoSection = "home-internet-checkout-module--fillInfoSection--2ae54";
export var fullPageLoader = "home-internet-checkout-module--fullPageLoader--10174";
export var fullWidthInput = "home-internet-checkout-module--fullWidthInput--fb79e";
export var homeInternetContainer = "home-internet-checkout-module--homeInternetContainer--a0ba1";
export var infoContainer = "home-internet-checkout-module--infoContainer--ecf11";
export var infoTitle = "home-internet-checkout-module--infoTitle--b296a";
export var infoValue = "home-internet-checkout-module--infoValue--d4ac5";
export var innerContainer = "home-internet-checkout-module--innerContainer--0ef35";
export var innerInputSpan = "home-internet-checkout-module--innerInputSpan--975be";
export var inputContainer = "home-internet-checkout-module--inputContainer--07a79";
export var inputContainerFlex = "home-internet-checkout-module--inputContainerFlex--6593e";
export var inputElement = "home-internet-checkout-module--inputElement--d25c0";
export var inputExplain = "home-internet-checkout-module--inputExplain--73cd8";
export var inputLabel = "home-internet-checkout-module--inputLabel--dce91";
export var inputNoticeInfo = "home-internet-checkout-module--inputNoticeInfo--24a35";
export var inputSpan = "home-internet-checkout-module--inputSpan--e0c41";
export var inputTitle = "home-internet-checkout-module--inputTitle--748ae";
export var installation = "home-internet-checkout-module--installation--e92f8";
export var leftSide = "home-internet-checkout-module--leftSide--a137b";
export var mainContainer = "home-internet-checkout-module--mainContainer--58990";
export var mainHeading = "home-internet-checkout-module--mainHeading--89750";
export var mainHeadingContainer = "home-internet-checkout-module--mainHeadingContainer--fa9f5";
export var mainTitle = "home-internet-checkout-module--mainTitle--422ad";
export var multipleInputContainer = "home-internet-checkout-module--multipleInputContainer--adee1";
export var nextButton = "home-internet-checkout-module--nextButton--b09f4";
export var notification = "home-internet-checkout-module--notification--70b8c";
export var originalPrice = "home-internet-checkout-module--originalPrice--57d7b";
export var otherInfoProviderContainer = "home-internet-checkout-module--otherInfoProviderContainer--7f11d";
export var otherInformation = "home-internet-checkout-module--otherInformation--75ad1";
export var personalIdentification = "home-internet-checkout-module--personalIdentification--01b38";
export var personalInformation = "home-internet-checkout-module--personalInformation--fc4fd";
export var phoneBoxCustomerNotice = "home-internet-checkout-module--phoneBoxCustomerNotice--69ea2";
export var planInfoSection = "home-internet-checkout-module--planInfoSection--ab0d4";
export var planPrice = "home-internet-checkout-module--planPrice--34b79";
export var preferredInstallationDateTimeInputContainer = "home-internet-checkout-module--preferredInstallationDateTimeInputContainer--4ca01";
export var priceCurrency = "home-internet-checkout-module--priceCurrency--6fd55";
export var priceDetail = "home-internet-checkout-module--priceDetail--c25f6";
export var priceInfo = "home-internet-checkout-module--priceInfo--b54e6";
export var priceMonth = "home-internet-checkout-module--priceMonth--fa1d5";
export var rightSide = "home-internet-checkout-module--rightSide--0869f";
export var rightSideBold = "home-internet-checkout-module--rightSideBold--3f84b";
export var rotate = "home-internet-checkout-module--rotate--e30ae";
export var specialDiscount = "home-internet-checkout-module--specialDiscount--c8949";
export var specialInfo = "home-internet-checkout-module--specialInfo--740b8";
export var subHeadingContainer = "home-internet-checkout-module--subHeadingContainer--08bbe";
export var submissionForm = "home-internet-checkout-module--submissionForm--5858b";
export var summaryContainer = "home-internet-checkout-module--summaryContainer--5a906";
export var termsAndConditionContainer = "home-internet-checkout-module--termsAndConditionContainer--f85c4";
export var thankYouPopUpContainer = "home-internet-checkout-module--thankYouPopUpContainer--f87d9";
export var thankYouPopUpContent = "home-internet-checkout-module--thankYouPopUpContent--ba33f";
export var thankYouPopUpContentContainer = "home-internet-checkout-module--thankYouPopUpContentContainer--836d0";
export var verificationFailed = "home-internet-checkout-module--verificationFailed--e14b9";
export var verificationPassed = "home-internet-checkout-module--verificationPassed--34a05";
export var withSpecialInfo = "home-internet-checkout-module--withSpecialInfo--aede3";