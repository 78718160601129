import React, { useState, useEffect, useRef } from "react";

export type AutoCompleteAddress = {
  streetAddress: string;
  city: string;
  stateProvinceShortName: string;
  countryShortName: string;
  zipPostal: string;
  countryLongName: string;
  stateProvinceLongName: string;
  fullAddress: string;
};

interface GoogleAutoCompleteAddressHomeInternetProps {
  onAutoCompleteAddress: (address: AutoCompleteAddress) => void;
  className?: string;
  placeholder?: string;
}

const GoogleAutoCompleteAddressHomeInternet: React.FC<
  GoogleAutoCompleteAddressHomeInternetProps
> = (props: GoogleAutoCompleteAddressHomeInternetProps) => {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);

  let autoComplete;
  const initAutocomplete = () => {
    // @ts-expect-error: isGoogleMapsLoaded is not defined
    if (!window.isGoogleMapsLoaded) {
      setTimeout(initAutocomplete, 500);
      return;
    }
    // @ts-expect-error: google is not defined
    if (!window.google) {
      return;
    }
    let obj;
    // @ts-expect-error: google is not defined
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      obj
    );
    autoComplete.setComponentRestrictions({ country: ["ca"] });
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () => handlePlaceSelect());
  };

  const handlePlaceSelect = () => {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    setQuery(query);
    // return obj can have different lengths
    // this logic has NOT been accounted for yet
    let addressComponents = addressObject.address_components;
    let streetAddress = `${findAddressNameByType(
      addressComponents,
      "street_number",
      false
    )} ${findAddressNameByType(addressComponents, "route", false)}`;
    let city = findAddressNameByType(addressComponents, "locality", false);
    if (city === "") {
      city = findAddressNameByType(addressComponents, "neighborhood", false);
    }

    let stateProvinceShortName = findAddressNameByType(
      addressComponents,
      "administrative_area_level_1",
      false
    );
    let stateProvinceLongName = findAddressNameByType(
      addressComponents,
      "administrative_area_level_1",
      true
    );
    let countryShortName = findAddressNameByType(
      addressComponents,
      "country",
      false
    );
    let countryLongName = findAddressNameByType(
      addressComponents,
      "country",
      true
    );
    let zipPostal = findAddressNameByType(
      addressComponents,
      "postal_code",
      false
    );

    const address = {
      streetAddress,
      city,
      stateProvinceShortName,
      countryShortName,
      zipPostal,
      countryLongName,
      stateProvinceLongName,
      fullAddress: `${streetAddress}, ${city}, ${stateProvinceShortName} ${countryShortName}, ${zipPostal}`,
    };

    props.onAutoCompleteAddress(address);
  };

  const findAddressNameByType = (
    addressComponents: any,
    componentType: string,
    isLongName: boolean
  ) => {
    for (let i = 0; i < addressComponents.length; i++) {
      let item = addressComponents[i];
      if (item.types.includes(componentType) && isLongName) {
        return item.long_name;
      } else if (item.types.includes(componentType) && !isLongName) {
        return item.short_name;
      }
    }
    return "";
  };

  useEffect(() => {
    initAutocomplete();
  }, [props.onAutoCompleteAddress]);

  return (
    <input
      className={props.className}
      type="text"
      placeholder={
        props.placeholder ?? "Enter your address to see available plans!"
      }
      ref={autoCompleteRef}
      value={query}
      onChange={(event) => setQuery(event.target.value)}
      onFocus={() => {
        window.document.body.classList.add("prevent-pointer-event");
      }}
      onBlur={() => {
        window.document.body.classList.remove("prevent-pointer-event");
      }}
    />
  );
};

export default GoogleAutoCompleteAddressHomeInternet;
