import React, { useRef } from "react";
import Input from "../../home-internet-content/components/Input";
import PhoneNumberInput from "../../home-internet-content/components/PhoneNumberInput";
import BillingMethodInputs from "../../home-internet-content/components/BillingMethodInputs";
import BillingAddressInputs from "../../home-internet-content/components/BillingAddressInputs";
import { FormInfoFilled, HomeInternetPlan } from "../constant-and-types";
import { AutoCompleteAddress } from "../../components/GoogleAutoCompleteAddressHomeInternet";
import CurrentProvider from "../components/CurrentProvider";
import moment from "moment";

import * as Styles from "../../pages/css/home-internet-checkout.module.scss";

const FillInfoForm = ({
  onFormSubmit,
  formData,
  address,
  selectedPlan,
}: {
  onFormSubmit: (e: any) => void;
  formData: FormInfoFilled;
  address: AutoCompleteAddress;
  selectedPlan: HomeInternetPlan;
}) => {
  const emailRef = useRef<HTMLInputElement>(null);

  return (
    <form
      id="home-internet-detail-form"
      onSubmit={onFormSubmit}
      className={Styles.submissionForm}
    >
      {/* Personal Information Block */}
      <div id={Styles.personalInformation}>
        <h4>Personal Information</h4>
        <div className={Styles.inputContainer}>
          <Input
            title="First Name"
            inputName="firstName"
            placeholder="John"
            isRequired={true}
            defaultValue={formData.firstName}
          />
          <Input
            title="Last Name"
            placeholder="Doe"
            inputName="lastName"
            isRequired={true}
            defaultValue={formData.lastName}
          />
          <Input
            title="Email Address"
            placeholder="username@domain.com"
            inputName="emailAddress"
            inputType="email"
            isRequired={true}
            defaultValue={formData.emailAddress}
            ref={emailRef}
          />
          <Input
            title="Confirm Email Address"
            placeholder="username@domain.com"
            inputName="confirmEmailAddress"
            inputType="email"
            isRequired={true}
            defaultValue={formData.confirmEmailAddress}
          />
          <PhoneNumberInput
            defaultValueIsPhoneBoxCustomer={formData.phoneBoxCustomer}
            defaultValuePhone={
              formData.phoneNumber === 0 ? "" : formData.phoneNumber.toString()
            }
            customerEmailRef={emailRef}
          />
        </div>
      </div>
      {/* Installation Block */}
      <div id={Styles.installation}>
        <h4>Installation</h4>
        <div className={Styles.inputContainer}>
          <Input
            inputName="streetAddress"
            title="Street Address"
            defaultValue={formData.streetAddress || address.streetAddress}
            disabled
          />
          <Input
            inputName="apartmentOrUnit"
            title="Apartment, Unit, etc"
            defaultValue={formData.apartmentOrUnit}
          />
          <Input
            inputName="city"
            title="City"
            defaultValue={formData.city || address.city}
            disabled
          />
          <Input
            inputName="province"
            title="Province"
            defaultValue={formData.province || address.stateProvinceLongName}
            disabled
          />
          <Input
            inputName="postalCode"
            title="Postal code"
            defaultValue={formData.postalCode || address.zipPostal}
            disabled
          />

          <label className={`${Styles.inputLabel} ${Styles.fullWidthInput}`}>
            <span className={Styles.inputSpan}>
              Preferred Installation Date & Time <sup>*</sup>
            </span>
            <div id={Styles.preferredInstallationDateTimeInputContainer}>
              <input
                className={Styles.inputElement}
                type="date"
                name="preferredInstallationDate"
                required
                defaultValue={formData.preferredInstallationDate}
                min={moment().add(5, "day").format("YYYY-MM-DD")}
                max={moment().add(1, "year").format("YYYY-MM-DD")}
              />
              <select
                name="preferredInstallationTime"
                required
                defaultValue={formData.preferredInstallationTime}
              >
                <option disabled value="">
                  Select time
                </option>
                <option value="08:00:00">Morning (8AM-12PM)</option>
                <option value="12:00:00">Afternoon (12PM-4PM)</option>
                <option value="00:00:00">Anytime</option>
              </select>
            </div>
          </label>
        </div>
        <ul>
          <li>
            Installation dates can be scheduled within 4 business days,
            excluding the order date.
          </li>
          <li>
            Installation is not available on statutory holidays or weekends.
          </li>
        </ul>
      </div>
      {/* Personal Identification Block */}
      <div id={Styles.personalIdentification}>
        <h4>Personal Identification</h4>
        <div className={Styles.inputContainer}>
          <Input
            inputName="dateOfBirth"
            title="Date of Birth"
            inputType="date"
            isRequired
            defaultValue={formData.dateOfBirth}
            min={moment().subtract(100, "year").format("YYYY-MM-DD")}
            max="2006-01-01"
          />
        </div>
      </div>
      {/* Other Information Block */}
      <div id={Styles.otherInformation}>
        <h4>Other Information</h4>
        <div
          className={`${Styles.inputContainer} ${Styles.inputContainerFlex}`}
        >
          <CurrentProvider
            haveNoInternetAtCurrentLocation={
              formData.noInternetAtCurrentLocation === null
                ? null
                : formData.noInternetAtCurrentLocation
            }
            currentServiceProvider={formData.currentServiceProvider}
          />
          {/* Preferred Language */}
          <label className={Styles.inputLabel}>
            <span className={Styles.inputSpan}>
              Preferred Language <sup>*</sup>
            </span>
            <select
              name="preferredLanguage"
              required
              defaultValue={formData.preferredLanguage}
            >
              <option disabled value="">
                Select Language
              </option>
              <option value="English">English</option>
              <option value="French">French</option>
            </select>
          </label>
        </div>
      </div>
      {/* Notification Block */}
      {selectedPlan.serviceNetw.toLowerCase() === "telus" ? (
        <div id={Styles.notification}>
          <h4>Notifications</h4>
          <div
            className={`${Styles.inputContainer} ${Styles.inputContainerFlex}`}
          >
            {/* Preferred billing method */}
            <BillingMethodInputs
              preferredBillingMethod={formData.preferredBillingMethod}
              billingPhoneNumber={formData.billingPhoneNumber}
              useSamePhoneForBilling={formData.useSamePhoneForBilling}
            />
            {/* Would you like to receive your bill at the same address? */}
            <BillingAddressInputs
              receiveBillAtSameAddress={formData.receiveBillAtSameAddress}
              billingStreetAddress={formData.billingStreetAddress}
              billingApartmentOrUnit={formData.billingApartmentOrUnit}
              billingCity={formData.billingCity}
              billingProvince={formData.billingProvince}
              billingPostalCode={formData.billingPostalCode}
            />
          </div>
        </div>
      ) : null}
    </form>
  );
};

export default FillInfoForm;
