export const EMAIL_REGEX =
  /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

export type FormInfoFilled = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  confirmEmailAddress: string;
  phoneBoxCustomer: boolean;
  phoneNumber: number;
  streetAddress: string;
  apartmentOrUnit: string;
  city: string;
  province: string;
  postalCode: string;
  billingStreetAddress?: string;
  billingApartmentOrUnit?: string;
  billingCity?: string;
  billingProvince?: string;
  billingPostalCode?: string;
  preferredInstallationDate: string;
  preferredInstallationTime: string;
  dateOfBirth: string;
  currentServiceProvider: string;
  noInternetAtCurrentLocation: boolean;
  preferredLanguage: string;
  preferredBillingMethod: string;
  billingPhoneNumber: number;
  useSamePhoneForBilling: boolean;
  receiveBillAtSameAddress: boolean;
};

export type PlanCategory = {
  planCat: string;
  remarks: string;
  currency: string;
  actFee: number;
  delivFee: number;
  promoCode: string;
  mcs: number;
};

export type HomeInternetPlan = {
  planId: number;
  planCat: string;
  planNote: string;
  planDays: number;
  planName: string;
  planFormal: string;
  planPrice: number;
  dataCapGB: number;
  dataOvrGB: number;
  serviceArea: string;
  serviceNetw: string;
  speedDnload: number;
  speedUpload: number;
  cnctDevices: null;
  planTitle1: null;
  planTitle2: string;
  orgPrice: number;
  papDiscount: number; //Pre-authorized payment discount/Seasonal discount
  kDiscount: number; //24 months contract discount
  kMonths: number; //Contract(Month)
  boxDiscount: number; //PhoneBox special discount
  supDiscountOneTime: number; //Sign up discount
  supDiscountMonthly: number; //Sign up discount
};
