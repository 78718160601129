import React, { forwardRef } from "react";

import * as Styles from "../../pages/css/home-internet-checkout.module.scss";

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  title: string;
  inputName: string;
  defaultValue?: string;
  isRequired?: boolean;
  inputType?: React.HTMLInputTypeAttribute;
}
const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ title, inputName, isRequired, inputType, ...props }, ref) => {
    return (
      <label
        className={Styles.inputLabel}
        style={props.hidden ? { display: "none" } : {}}
      >
        <span className={Styles.inputSpan}>
          {title}
          {isRequired ? <sup>*</sup> : null}
        </span>
        <input
          className={Styles.inputElement}
          type={inputType ?? "text"}
          name={inputName}
          required={isRequired}
          ref={ref}
          {...props}
        />
      </label>
    );
  }
);

export default Input;
