import * as React from "react";

const Close = ({ color = "#fff", ...props }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill={color}
      d="M7.999 9.633 2.282 15.35c-.214.214-.486.32-.816.32-.33 0-.603-.106-.817-.32a1.106 1.106 0 0 1-.32-.817c0-.33.106-.602.32-.816L6.366 8 .649 2.283a1.106 1.106 0 0 1-.32-.816c0-.331.106-.603.32-.817.214-.214.486-.32.817-.32.33 0 .602.106.816.32L8 6.367 13.716.65c.214-.214.486-.32.816-.32.33 0 .603.106.817.32.214.214.32.486.32.817 0 .33-.106.602-.32.816L9.632 8l5.717 5.717c.214.213.32.486.32.816 0 .33-.106.603-.32.817-.214.214-.486.32-.817.32-.33 0-.602-.106-.816-.32L7.999 9.633Z"
    />
  </svg>
);
export default Close;
