import { AxiosInstance, AxiosResponse } from "axios";
import instance from "./axios.js";
import {
  HomeInternetPlan,
  PlanCategory,
} from "../home-internet-content/constant-and-types";

const apiInstance: AxiosInstance = instance;

type ApiResponse<T> = {
  data: T;
  error: string;
  message: string;
  success: boolean;
};

type PlanList = {
  planCategory: PlanCategory;
  planDetails: HomeInternetPlan[];
};

interface PlanBillingInfo extends PlanList {
  gstrate: number;
  pstrate: number;
  gstamt: number;
  pstamt: number;
  subtotal: number;
  total: number;
  charge_duration: string;
}

type PendingOrderData = {
  PlanId: number;
  FirstName: string;
  LastName: string;
  EmailAddr: string;
  PlanProvince: string;
  PlanCity: string;
  Promo_Code: string;
  D_Address: string;
  D_Province: string;
  D_Postal: string;
  B_Address?: string;
  B_Province?: string;
  B_Postal?: string;
  ContactPnum: number;
  withBox: boolean;
  birthday: string;
  billEmail: boolean;
  billSms: boolean;
  billSmsPnum: number;
  currProvider: string;
  noInternet: boolean;
  lang: string;
  installDate: string;
};

const isTesting =
  typeof window !== "undefined" &&
  [
    "localhost",
    "kind-grass-0097d191e-926.westus2.1.azurestaticapps.net",
    "testhp2021.gophonebox.com",
  ].includes(window.location.hostname);

export const homeInternetApi = {
  checkIfServiceAvailable: (
    provinceCode: string
  ): Promise<AxiosResponse<ApiResponse<boolean>>> =>
    apiInstance.get(
      `/Activation25/IsServiceAvailable?provinceCode=${provinceCode}`
    ),
  getPromocode: (): Promise<AxiosResponse<ApiResponse<string>>> =>
    apiInstance.get(
      isTesting
        ? "/Activation25/GetPromocode?testenv=yes"
        : "/Activation25/GetPromocode"
    ),
  getPlanList: (
    provinceCode: string,
    promocode: string
  ): Promise<AxiosResponse<ApiResponse<PlanList>>> =>
    apiInstance.get(
      `/Activation25/GetPlanList?promocode=${promocode}&provinceCode=${provinceCode}`
    ),
  isOurPhoneNumber: (
    phoneNumber: number,
    emailAddress: string
  ): Promise<AxiosResponse<ApiResponse<boolean>>> =>
    apiInstance.get(
      `/Activation25/IsOurPhoneNumber?phoneNumber=${phoneNumber}&emailAddress=${emailAddress}`
    ),
  submitPendingOrder: (
    postData: PendingOrderData
  ): Promise<AxiosResponse<ApiResponse<number>>> =>
    apiInstance.post("/Activation25/PostActivation", postData),
  submitConfirmOrder: (
    oaid: number,
    phoneNumber: number,
    emailAddress: string
  ): Promise<AxiosResponse<ApiResponse<number>>> =>
    apiInstance.post(
      `/Activation25/PostActivationApproval?phoneNumber=${phoneNumber}&emailAddress=${emailAddress}`,
      null,
      {
        headers: {
          oaid: oaid,
        },
      }
    ),
};
