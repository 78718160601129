import * as React from "react";
const RightArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={8}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M1 3.5a.5.5 0 0 0 0 1v-1Zm24.354.854a.5.5 0 0 0 0-.708L22.172.464a.5.5 0 1 0-.707.708L24.293 4l-2.828 2.828a.5.5 0 1 0 .707.708l3.182-3.182ZM1 4.5h24v-1H1v1Z"
    />
  </svg>
);
export default RightArrow;
