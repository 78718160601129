import * as React from "react";

const Checkbox = ({
  checked,
  ...props
}: {
  checked: boolean;
  onClick: () => void;
}) => (
  <div style={{ cursor: "pointer", width: "30px", height: "30px" }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      fill="none"
      onClick={() => props.onClick()}
      {...props}
    >
      {checked ? (
        <path
          fill="#000"
          d="M3.5 0H24.5C26.432 0 28 1.568 28 3.5V24.5C28 25.4283 27.6313 26.3185 26.9749 26.9749C26.3185 27.6313 25.4283 28 24.5 28H3.5C2.57174 28 1.6815 27.6313 1.02513 26.9749C0.368749 26.3185 0 25.4283 0 24.5V3.5C0 1.568 1.568 0 3.5 0ZM3 3.5V24.5C3 24.776 3.224 25 3.5 25H24.5C24.6326 25 24.7598 24.9473 24.8536 24.8536C24.9473 24.7598 25 24.6326 25 24.5V3.5C25 3.36739 24.9473 3.24021 24.8536 3.14645C24.7598 3.05268 24.6326 3 24.5 3H3.5C3.36739 3 3.24021 3.05268 3.14645 3.14645C3.05268 3.24021 3 3.36739 3 3.5ZM21.56 10.56L12.56 19.56C12.2787 19.8409 11.8975 19.9987 11.5 19.9987C11.1025 19.9987 10.7213 19.8409 10.44 19.56L6.44 15.56C6.17471 15.2757 6.03019 14.8995 6.03691 14.5107C6.04363 14.1219 6.20105 13.751 6.476 13.476C6.75096 13.2011 7.12194 13.0436 7.51073 13.0369C7.89951 13.0302 8.27571 13.1747 8.56 13.44L11.5 16.38L19.44 8.44C19.7243 8.17471 20.1005 8.03019 20.4893 8.03691C20.8781 8.04363 21.249 8.20105 21.524 8.476C21.7989 8.75096 21.9564 9.12194 21.9631 9.51073C21.9698 9.89951 21.8253 10.2757 21.56 10.56Z"
        />
      ) : (
        <path
          fill="#000"
          d="M3.5 0.299291H24.5C26.432 0.299291 28 1.86729 28 3.79929V24.7993C28 25.7275 27.6313 26.6178 26.9749 27.2742C26.3185 27.9305 25.4283 28.2993 24.5 28.2993H3.5C2.57174 28.2993 1.6815 27.9305 1.02513 27.2742C0.368749 26.6178 0 25.7275 0 24.7993V3.79929C0 1.86729 1.568 0.299291 3.5 0.299291ZM3 3.79929V24.7993C3 25.0753 3.224 25.2993 3.5 25.2993H24.5C24.6326 25.2993 24.7598 25.2466 24.8536 25.1528C24.9473 25.0591 25 24.9319 25 24.7993V3.79929C25 3.66668 24.9473 3.53951 24.8536 3.44574C24.7598 3.35197 24.6326 3.29929 24.5 3.29929H3.5C3.36739 3.29929 3.24021 3.35197 3.14645 3.44574C3.05268 3.53951 3 3.66668 3 3.79929Z"
        />
      )}
    </svg>
  </div>
);
export default Checkbox;
