import React, { useEffect, useState } from "react";
import { FormInfoFilled } from "../constant-and-types";

import * as Styles from "../../pages/css/home-internet-checkout.module.scss";
import { toast } from "react-toastify";
import Input from "./Input";

const otherProviderList = [
  "Bell Canada (Bell/Virgin)",
  "Rogers Communications (Rogers/Shaw/Fido)",
  "Telus/Koodo",
  "Freedom",
  "Cogeco",
  "Videotron",
  "Eastlink",
  "SaskTel",
  "Xplornet",
  "Teksavvy",
  "Distributel",
  "Others",
];

const CurrentProvider = ({
  haveNoInternetAtCurrentLocation,
  currentServiceProvider,
}: {
  haveNoInternetAtCurrentLocation: boolean | null;
  currentServiceProvider: string;
}) => {
  const [haveNoInternet, setHaveNoInternet] = useState<boolean>(
    haveNoInternetAtCurrentLocation
  );

  const [provider, setProvider] = useState<string>(
    otherProviderList.includes(currentServiceProvider)
      ? currentServiceProvider
      : "Others"
  );

  useEffect(() => {
    if (provider === "Telus/Koodo") {
      toast.warn(
        "We're Sorry. PhoneBox is not able to support your current internet provider if it is TELUS or Koodo. Please contact TELUS/Koodo customer support for further assistance (+1-855-886-0505)."
      );
    }
  }, [provider]);

  return (
    <div className={Styles.otherInfoProviderContainer}>
      <label className={Styles.checkboxInput}>
        <input
          className={Styles.inputElement}
          type="checkbox"
          name="noInternetAtCurrentLocation"
          value="noInternetAtCurrentLocation"
          checked={haveNoInternet === true}
          onChange={() => {
            setHaveNoInternet((_haveNoInternet) => !_haveNoInternet);
          }}
        />
        <span className={Styles.inputTitle}>
          There is no internet connection at this address.
        </span>
      </label>
      {haveNoInternet ? null : (
        <>
          {/* Please select your current service provider* */}
          <label className={Styles.inputLabel}>
            <span className={Styles.inputSpan}>
              Please select your current service provider <sup>*</sup>
            </span>
            <select
              required
              value={otherProviderList.includes(provider) ? provider : "Others"}
              onChange={(e) => setProvider(e.target.value)}
            >
              <option disabled value="">
                Select Provider
              </option>
              {otherProviderList.map((p) => (
                <option key={p} value={p}>
                  {p}
                </option>
              ))}
            </select>
          </label>
          <div className={Styles.inputContainer}>
            <Input
              hidden={
                otherProviderList.includes(provider) && provider !== "Others"
              }
              inputName="currentServiceProvider"
              title="Other Service Provider Name"
              value={provider === "Others" ? "" : provider}
              onChange={(e) => {
                if (!otherProviderList.includes(e.target.value)) {
                  setProvider(e.target.value);
                }
              }}
              isRequired
              maxLength={45}
            />
          </div>
          <p className={Styles.inputNoticeInfo}>
            If your current provider is not listed, please call one of our
            support agent at <span>(855)-866-0505</span>
          </p>
        </>
      )}
    </div>
  );
};

export default CurrentProvider;
