import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { homeInternetApi } from "../../utilities/home-internet-api";
import { EMAIL_REGEX } from "../constant-and-types";
import Input from "./Input";

import * as Styles from "../../pages/css/home-internet-checkout.module.scss";

/**
 * To limit re-render of all inputs on
 * Are you already using PhoneBox? change
 */
const PhoneNumberInput = (props: {
  defaultValueIsPhoneBoxCustomer: boolean;
  defaultValuePhone: string;
  customerEmailRef: React.MutableRefObject<HTMLInputElement>;
}) => {
  const [isPhoneboxCustomer, setIsPhoneboxCustomer] = useState<boolean>(
    props.defaultValueIsPhoneBoxCustomer
  );

  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const [sPhoneBoxCustomerError, setIsPhoneBoxCustomerError] =
    useState<boolean>(null);

  const validatePhoneNumber = async () => {
    const email = props.customerEmailRef.current?.value;
    const phoneNumber = phoneNumberRef.current.value;
    if (!EMAIL_REGEX.test(email)) {
      return;
    }
    if (isNaN(Number(phoneNumber)) || phoneNumber.length !== 10) {
      return;
    }
    try {
      const response = await homeInternetApi.isOurPhoneNumber(
        Number(phoneNumber),
        email
      );
      if (response.data.success) {
        setIsPhoneBoxCustomerError(!response.data.data);
        setIsPhoneboxCustomer(
          response.data.data === false ? null : response.data.data
        );
      } else {
        setIsPhoneBoxCustomerError(true);
        setIsPhoneboxCustomer(false);
        phoneNumberRef.current.value = "";
        if (response.data.message) {
          toast.error(response.data.message);
        } else {
          toast.error("Unable to Verify your phone number, please try again");
        }
      }
    } catch (error) {
      setIsPhoneBoxCustomerError(true);
      setIsPhoneboxCustomer(null);
      phoneNumberRef.current.value = "";
      toast.error("Unable to Verify your phone number, please try again");
    }
  };

  return (
    <>
      <div className={Styles.inputLabel}>
        <span className={Styles.inputSpan}>
          Are you already using PhoneBox?<sup>*</sup>
        </span>
        <div className={Styles.multipleInputContainer}>
          <label>
            <input
              className={Styles.inputElement}
              type="radio"
              name="phoneBoxCustomer"
              value="yes"
              checked={isPhoneboxCustomer === true}
              onChange={() => {
                setIsPhoneBoxCustomerError(null);
                setIsPhoneboxCustomer(true);
              }}
              required
            />
            <span className={Styles.innerInputSpan}>Yes</span>
          </label>
          <label>
            <input
              className={Styles.inputElement}
              type="radio"
              name="phoneBoxCustomer"
              value="no"
              checked={isPhoneboxCustomer === false}
              onChange={() => {
                setIsPhoneBoxCustomerError(null);
                setIsPhoneboxCustomer(false);
              }}
              required
            />
            <span className={Styles.innerInputSpan}>No</span>
          </label>
        </div>
      </div>
      <span className={Styles.phoneBoxCustomerNotice}>
        If you are a PhoneBox customer, You can get <span>a Big Discount!</span>
      </span>
      {isPhoneboxCustomer ? (
        <label className={Styles.inputLabel}>
          <span className={Styles.inputSpan}>
            <img
              src="/images/home-internet/phone-box-logo.png"
              alt="Phone Box Brand Logo"
            />
            <span>Current PhoneBox Phone number</span>
            <sup>*</sup>
          </span>
          <input
            className={Styles.inputElement}
            type="text"
            pattern="\d*"
            name="phoneNumber"
            placeholder="1234567890"
            required
            defaultValue={props.defaultValuePhone}
            ref={phoneNumberRef}
            minLength={10}
            maxLength={10}
            onChange={() => {
              setIsPhoneBoxCustomerError(null);
            }}
            onBlur={() => {
              if (!EMAIL_REGEX.test(props.customerEmailRef.current?.value)) {
                phoneNumberRef.current.value = "";
                return toast.error("Please enter your email address first");
              } else {
                validatePhoneNumber();
              }
            }}
          />
        </label>
      ) : (
        <Input
          title="Phone number"
          placeholder="1234567890"
          inputName="phoneNumber"
          isRequired={true}
          inputType="text"
          pattern="\d*"
          defaultValue={props.defaultValuePhone}
          ref={phoneNumberRef}
          minLength={10}
          maxLength={10}
        />
      )}
      {sPhoneBoxCustomerError === true ? (
        <span id={Styles.verificationFailed}>
          Verification Failed. Please Enter a correct Phone Number.
        </span>
      ) : sPhoneBoxCustomerError === false ? (
        <span id={Styles.verificationPassed}>
          ✅ Your Phone Number is verified.
        </span>
      ) : null}
    </>
  );
};

export default PhoneNumberInput;
