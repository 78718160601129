import React, { useState } from "react";
import { provinces } from "../../utilities/constants";
import GoogleAutoCompleteAddressHomeInternet, {
  AutoCompleteAddress,
} from "../../components/GoogleAutoCompleteAddressHomeInternet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Input from "./Input";

import * as Styles from "../../pages/css/home-internet-checkout.module.scss";

const BillingAddressInputs = (props: {
  receiveBillAtSameAddress: boolean;
  billingStreetAddress: string;
  billingApartmentOrUnit: string;
  billingCity: string;
  billingProvince: string;
  billingPostalCode: string;
}) => {
  const [isUsingSameBillingAddress, setIsUsingSameBillingAddress] =
    useState<boolean>(props.receiveBillAtSameAddress ?? true);

  const [address, setAddress] = useState<AutoCompleteAddress>({
    city: props.billingCity,
    countryLongName: "Canada",
    countryShortName: "CAN",
    fullAddress: "",
    stateProvinceLongName: props.billingProvince,
    stateProvinceShortName: props.billingProvince,
    streetAddress: props.billingStreetAddress,
    zipPostal: props.billingPostalCode,
  });

  return (
    <>
      <div className={Styles.inputLabel}>
        <span className={Styles.inputSpan}>
          Would you like to receive your bill at the same address?
        </span>
        <div className={Styles.multipleInputContainer}>
          <label>
            <input
              className={Styles.inputElement}
              type="radio"
              name="receiveBillAtSameAddress"
              value="yes"
              defaultChecked={props.receiveBillAtSameAddress === true}
              required
              onChange={() => {
                setIsUsingSameBillingAddress(true);
              }}
            />
            <span className={Styles.innerInputSpan}>Yes</span>
          </label>
          <label>
            <input
              className={Styles.inputElement}
              type="radio"
              name="receiveBillAtSameAddress"
              value="no"
              defaultChecked={props.receiveBillAtSameAddress === false}
              required
              onChange={() => {
                setIsUsingSameBillingAddress(false);
              }}
            />
            <span className={Styles.innerInputSpan}>No</span>
          </label>
        </div>
      </div>
      {isUsingSameBillingAddress ? null : (
        <p className={Styles.inputNoticeInfo}>
          Please enter the address where you'd like to receive bill
          notifications
        </p>
      )}
      {isUsingSameBillingAddress ? null : (
        <div className={Styles.inputContainer}>
          <label className={Styles.inputLabel}>
            <span className={Styles.inputSpan}>
              <FontAwesomeIcon
                icon={faSearch}
                style={{
                  marginRight: "10px",
                }}
              />
              Search
            </span>
            <GoogleAutoCompleteAddressHomeInternet
              placeholder="Start typing your address"
              className={Styles.inputElement}
              onAutoCompleteAddress={(address) => {
                setAddress(address);
              }}
            />
          </label>
          <Input
            inputName="billingStreetAddress"
            title="Street Address"
            isRequired
            defaultValue={address.streetAddress}
          />
          <Input
            inputName="billingApartmentOrUnit"
            title="Apartment, Unit, etc"
            defaultValue={
              address.fullAddress === "" ? props.billingApartmentOrUnit : ""
            }
          />
          <Input
            inputName="billingCity"
            title="City"
            isRequired
            defaultValue={address.city}
          />
          <label className={Styles.inputLabel}>
            <span className={Styles.inputSpan}>
              Province <sup>*</sup>
            </span>
            <select
              name="billingProvince"
              required
              value={address.stateProvinceLongName}
              onChange={(e) => {
                setAddress((a) => ({
                  ...a,
                  stateProvinceLongName: e.target.value,
                  stateProvinceShortName: e.target.value,
                }));
              }}
            >
              <option disabled value="">
                Select Province
              </option>
              {provinces.map((p) => (
                <option key={p.name} value={p.name}>
                  {p.name}
                </option>
              ))}
            </select>
          </label>
          <Input
            inputName="billingPostalCode"
            title="Postal code"
            isRequired
            defaultValue={address.zipPostal}
          />
        </div>
      )}
    </>
  );
};

export default BillingAddressInputs;
