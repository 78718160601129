import React from "react";

const Discount = (props: { color?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none">
    <path
      stroke={props.color ?? "#000"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m14 8-6 6m6 0h-.01M8.01 8H8M6.692 18.616c.59 0 .886 0 1.155.1.038.014.075.029.11.046.262.12.47.328.889.746.962.962 1.443 1.443 2.034 1.488.08.006.16.006.24 0 .59-.045 1.072-.526 2.034-1.488.418-.418.627-.626.888-.746.036-.017.072-.032.11-.046.27-.1.565-.1 1.156-.1h.11c1.507 0 2.26 0 2.73-.468.469-.468.468-1.223.468-2.73v-.11c0-.59 0-.886.1-1.155.014-.038.029-.075.046-.111.12-.261.328-.47.746-.888.962-.962 1.443-1.443 1.488-2.034.006-.08.006-.16 0-.24-.045-.591-.526-1.072-1.488-2.034-.418-.418-.626-.627-.746-.888l-.046-.11c-.1-.27-.1-.565-.1-1.156v-.11c0-1.507 0-2.261-.468-2.73-.468-.47-1.223-.468-2.73-.468h-.11c-.59 0-.886 0-1.155-.1l-.111-.046c-.261-.12-.47-.328-.888-.746-.962-.962-1.443-1.443-2.034-1.488a2.001 2.001 0 0 0-.24 0c-.591.045-1.072.526-2.034 1.488-.418.418-.627.627-.888.746l-.11.046c-.27.1-.565.1-1.156.1h-.11c-1.507 0-2.261 0-2.73.468-.47.468-.468 1.223-.468 2.73v.11c0 .59 0 .886-.1 1.155-.015.038-.03.075-.046.11-.12.262-.328.47-.746.889-.962.962-1.443 1.443-1.488 2.034-.005.08-.005.16 0 .24.045.59.526 1.072 1.488 2.034.418.418.627.627.746.888.016.036.032.072.046.11.1.27.1.565.1 1.156v.11c0 1.507 0 2.26.468 2.73.468.469 1.223.468 2.73.468h.11Z"
    />
  </svg>
);

export default Discount;
