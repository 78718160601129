import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
import * as Styles from "../css/home-internet-checkout.module.scss";
import PageLayout from "../../components/PageLayout";
import { graphql, navigate } from "gatsby";
import { Trans } from "react-i18next";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { homeInternetApi } from "../../utilities/home-internet-api";
import {
  EMAIL_REGEX,
  FormInfoFilled,
} from "../../home-internet-content/constant-and-types";
import FillInfoForm from "../../home-internet-content/page-part/FillInfoForm";
import SummaryView from "../../home-internet-content/page-part/SummaryView";
import PlanInfoPart from "../../home-internet-content/page-part/PlanInfoPart";
import Checkbox from "../../home-internet-content/icons/Checkbox";
import ThankYouPopUp from "../../home-internet-content/components/ThankYouPopUp";
import { useDispatch } from "react-redux";
import { saveRouteDetails } from "../../redux/actions/routeActions";
import ConsentPopUp from "../../home-internet-content/components/ConsentPopUp";

const defaultValue = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  confirmEmailAddress: "",
  phoneBoxCustomer: null,
  phoneNumber: 0,
  streetAddress: "",
  apartmentOrUnit: "",
  city: "",
  province: "",
  postalCode: "",
  billingStreetAddress: "",
  billingApartmentOrUnit: "",
  billingCity: "",
  billingProvince: "",
  billingPostalCode: "",
  preferredInstallationDate: "",
  preferredInstallationTime: "",
  dateOfBirth: "",
  currentServiceProvider: "",
  noInternetAtCurrentLocation: null,
  preferredLanguage: "",
  preferredBillingMethod: "",
  billingPhoneNumber: 0,
  useSamePhoneForBilling: null,
  receiveBillAtSameAddress: null,
};

const HomeInternetCheckout = () => {
  const dispatch = useDispatch();

  const { selectedPlan, selectedPlanId, promoCode, address } = useSelector(
    (state: RootState) => state.homeInternet
  );

  const [termsConditionChecked, setTermsConditionChecked] =
    useState<boolean>(false);
  const [systemCheckConditionChecked, setSystemCheckConditionChecked] =
    useState<boolean>(false);
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const [showConsent, setShowConsent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormInfoFilled>(defaultValue);
  const [submitPendingOrderResponseId, setSubmitPendingOrderResponseId] =
    useState<number>(null);
  const [orderPlaced, setOrderPlaced] = useState<boolean>(false);

  useEffect(() => {
    if (!selectedPlan || !selectedPlanId || !promoCode || !address) {
      navigate("/home-internet");
      return;
    }
  }, [selectedPlan, selectedPlanId, promoCode, address, orderPlaced]);

  useEffect(() => {
    dispatch(saveRouteDetails("home-internet") as any);
  }, []);

  const onFormSubmit = async (e) => {
    window.scroll(0, 0);
    e.preventDefault();
    const formElement = e.currentTarget;
    const formData = new FormData(formElement);
    console.log(formData);

    const data: FormInfoFilled = {
      firstName: formData.get("firstName") as string,
      lastName: formData.get("lastName") as string,
      emailAddress: formData.get("emailAddress") as string,
      confirmEmailAddress: formData.get("confirmEmailAddress") as string,
      phoneBoxCustomer: formData.get("phoneBoxCustomer") === "yes",
      phoneNumber: Number(formData.get("phoneNumber")) as number,
      streetAddress: address.streetAddress,
      apartmentOrUnit: formData.get("apartmentOrUnit") as string,
      city: address.city,
      province: address.stateProvinceLongName,
      postalCode: address.zipPostal,
      preferredInstallationDate: formData.get(
        "preferredInstallationDate"
      ) as string,
      preferredInstallationTime: formData.get(
        "preferredInstallationTime"
      ) as string,
      billingStreetAddress: formData.get("billingStreetAddress") as string,
      billingApartmentOrUnit: formData.get("billingApartmentOrUnit") as string,
      billingCity: formData.get("billingCity") as string,
      billingProvince: formData.get("billingProvince") as string,
      billingPostalCode: formData.get("billingPostalCode") as string,
      dateOfBirth: formData.get("dateOfBirth") as string,
      currentServiceProvider: formData.get("currentServiceProvider") as string,
      noInternetAtCurrentLocation:
        formData.get("noInternetAtCurrentLocation") ===
        "noInternetAtCurrentLocation",
      preferredLanguage: formData.get("preferredLanguage") as string,
      preferredBillingMethod: formData.get("preferredBillingMethod") as string,
      billingPhoneNumber: Number(formData.get("billingPhoneNumber")) as number,
      useSamePhoneForBilling: formData.get("useSamePhoneForBilling") === "yes",
      receiveBillAtSameAddress:
        formData.get("receiveBillAtSameAddress") === "yes",
    };
    if (!EMAIL_REGEX.test(data.emailAddress)) {
      return toast.error("Email Address is incorrect");
    }
    if (data.emailAddress !== data.confirmEmailAddress) {
      return toast.error(
        "Email Address and Confirm Email Address does not match"
      );
    }
    if (isNaN(data.phoneNumber) || data.phoneNumber.toString().length !== 10) {
      return toast.error("Invalid Phone Number");
    }
    if (/(?:telus|koodo)/i.test(data.currentServiceProvider)) {
      return toast.error(
        "We're Sorry. PhoneBox is not able to support your current internet provider if it is TELUS or Koodo. Please contact TELUS/Koodo customer support for further assistance (+1-855-886-0505)."
      );
    }
    setFormData(data);
    console.log("data: ", data);
    setShowSummary(true);
    try {
      setIsLoading(true);
      const response = await homeInternetApi.submitPendingOrder({
        PlanId: selectedPlan.planId,
        FirstName: data.firstName,
        LastName: data.lastName,
        EmailAddr: data.emailAddress,
        PlanProvince: data.province,
        PlanCity: data.city,
        Promo_Code: promoCode,
        D_Address: `${data.apartmentOrUnit} ${data.streetAddress}, ${data.city}`,
        D_Province: data.province,
        D_Postal: data.postalCode,
        B_Address:
          selectedPlan.serviceNetw.toLowerCase() !== "telus"
            ? null
            : data.receiveBillAtSameAddress === false
            ? `${data.billingApartmentOrUnit} ${data.billingStreetAddress}, ${data.billingCity}`
            : null,
        B_Postal:
          selectedPlan.serviceNetw.toLowerCase() !== "telus"
            ? null
            : data.receiveBillAtSameAddress === false
            ? data.billingPostalCode
            : null,
        B_Province:
          selectedPlan.serviceNetw.toLowerCase() !== "telus"
            ? null
            : data.receiveBillAtSameAddress === false
            ? data.billingProvince
            : null,
        ContactPnum: data.phoneNumber,
        withBox: data.phoneBoxCustomer,
        birthday: `${data.dateOfBirth}T00:00:00`,
        billEmail:
          selectedPlan.serviceNetw.toLowerCase() !== "telus"
            ? null
            : data.preferredBillingMethod === "Email" ||
              data.preferredBillingMethod === "Email & SMS",
        billSms:
          selectedPlan.serviceNetw.toLowerCase() !== "telus"
            ? null
            : data.preferredBillingMethod === "SMS" ||
              data.preferredBillingMethod === "Email & SMS",
        billSmsPnum:
          data.preferredBillingMethod === "SMS" ||
          data.preferredBillingMethod === "Email & SMS"
            ? data.billingPhoneNumber
            : null,
        currProvider: data.currentServiceProvider,
        noInternet:
          data.currentServiceProvider === "" ||
          data.noInternetAtCurrentLocation,
        lang: data.preferredLanguage,
        installDate: `${data.preferredInstallationDate}T${data.preferredInstallationTime}`,
      });
      if (response.data.success) {
        setSubmitPendingOrderResponseId(response.data.data);
      } else {
        setShowSummary(false);
        if (response.data.message) {
          toast.error(response.data.message);
        } else {
          toast.error("Unable to Submit Order, Please try again.");
        }
      }
    } catch (error) {
      setShowSummary(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmitOrder = async () => {
    window.scroll(0, 0);
    try {
      setIsLoading(true);
      const response = await homeInternetApi.submitConfirmOrder(
        submitPendingOrderResponseId,
        formData.phoneNumber,
        formData.emailAddress
      );
      if (response.data.success) {
        toast.success(
          "Your order has been placed. Our team will contact you if we require any additional information."
        );
        setOrderPlaced(true);
      } else {
        if (response.data.message) {
          toast.error(response.data.message);
        } else {
          toast.error("Unable to Submit order, Please try again.");
        }
      }
    } catch (error) {
      toast.error("Unable to Submit order, Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return !selectedPlan || !selectedPlanId || !promoCode || !address ? null : (
    <PageLayout
      showPartnerNav
      containerClassName={Styles.homeInternetContainer}
    >
      <title>
        <Trans>Home Internet - Checkout | PhoneBox</Trans>
      </title>
      {isLoading ? (
        <div id={Styles.fullPageLoader}>
          <span></span>
          <p>In progress, please do not refresh the page.</p>
        </div>
      ) : null}
      <h1 id={Styles.mainHeading}>Home Internet Service Information</h1>
      <main id={Styles.mainContainer}>
        <section id={Styles.fillInfoSection}>
          {showSummary ? (
            <SummaryView formData={formData} selectedPlan={selectedPlan} />
          ) : (
            <FillInfoForm
              address={address}
              formData={formData}
              onFormSubmit={onFormSubmit}
              selectedPlan={selectedPlan}
            />
          )}
        </section>
        <section id={Styles.planInfoSection}>
          <PlanInfoPart selectedPlan={selectedPlan} />
        </section>
        <div id={Styles.buttonContainer}>
          {showSummary ? (
            <div>
              <div className={Styles.termsAndConditionContainer}>
                <Checkbox
                  checked={termsConditionChecked}
                  onClick={() => {
                    setTermsConditionChecked((previousValue) => !previousValue);
                  }}
                />
                <p>
                  I have read and understand{" "}
                  <strong
                    onClick={() => {
                      setShowConsent(true);
                    }}
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Consent Agreement
                  </strong>{" "}
                  and agree to receive personalized commercial communications
                  from PhoneBox.
                </p>
              </div>
              <div className={Styles.termsAndConditionContainer}>
                <Checkbox
                  checked={systemCheckConditionChecked}
                  onClick={() => {
                    setSystemCheckConditionChecked(
                      (previousValue) => !previousValue
                    );
                  }}
                />
                <p>
                  I agree that a system check will be performed on the provided
                  physical address to verify eligibility for the selected plan.
                  I understand that this process is necessary to confirm pricing
                  and availability, and that I will be contacted for final
                  confirmation.
                </p>
              </div>
              <div>
                <button
                  onClick={() => {
                    setShowSummary(false);
                  }}
                >
                  Back & Edit
                </button>
                <button
                  className={Styles.nextButton}
                  type="button"
                  onClick={() => {
                    if (
                      !termsConditionChecked ||
                      !systemCheckConditionChecked
                    ) {
                      return toast.info(
                        "Please accept the Terms and Conditions before proceeding."
                      );
                    }
                    onSubmitOrder();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          ) : (
            <>
              <button
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </button>
              <button
                className={Styles.nextButton}
                type="submit"
                form="home-internet-detail-form"
                id="form-submit-button:checkout-home-internet"
              >
                Next
              </button>
            </>
          )}
        </div>
      </main>
      {orderPlaced ? (
        <ThankYouPopUp isExistingCustomer={formData.phoneBoxCustomer} />
      ) : null}
      {showConsent ? (
        <ConsentPopUp
          closeConsentPopUp={() => {
            setShowConsent(false);
          }}
        />
      ) : null}
    </PageLayout>
  );
};

export default HomeInternetCheckout;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
