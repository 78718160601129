import React from "react";
import Discount from "../icons/Discount";

import * as Styles from "../../pages/css/home-internet-checkout.module.scss";
import { HomeInternetPlan } from "../constant-and-types";

const PlanInfoPart = ({ selectedPlan }: { selectedPlan: HomeInternetPlan }) => (
  <>
    <div id={Styles.mainHeadingContainer}>
      <h6 className={Styles.mainTitle}>Home internet</h6>
      {selectedPlan.orgPrice !== selectedPlan.planPrice ? (
        <div id={Styles.originalPrice} className={Styles.priceDetail}>
          <sup>$</sup>
          <span className={Styles.planPrice}>{selectedPlan.orgPrice}</span>
          <span className={Styles.priceInfo}>
            <span className={Styles.priceCurrency}>CAD</span>
            <span className={Styles.priceMonth}>/month</span>
          </span>
        </div>
      ) : null}
    </div>
    <div id={Styles.subHeadingContainer}>
      <h6>{selectedPlan.planFormal}</h6>
      <div className={Styles.priceDetail}>
        <sup>$</sup>
        <span className={Styles.planPrice}>{selectedPlan.planPrice}</span>
        <span className={Styles.priceInfo}>
          <span className={Styles.priceCurrency}>CAD</span>
          <span className={Styles.priceMonth}>/month</span>
        </span>
      </div>
    </div>
    <p className={Styles.discountEligibleInfo}>
      You may be eligible for all the discounts listed below.
    </p>
    <hr />

    {selectedPlan.supDiscountOneTime ? (
      <div className={Styles.discountInfo}>
        <div className={Styles.leftSide}>
          <Discount />
          <div className={Styles.withSpecialInfo}>
            <span className={Styles.discountTitle}>
              $100 for sign up discount
            </span>
            <span className={Styles.specialInfo}>
              * Applied to <span>activation fee</span>
            </span>
          </div>
        </div>
        <span className={Styles.rightSide}>-$100</span>
      </div>
    ) : null}

    {selectedPlan.supDiscountMonthly ? (
      <div className={Styles.discountInfo}>
        <div className={Styles.leftSide}>
          <Discount />
          <span className={Styles.discountTitle}>
            ${selectedPlan.supDiscountMonthly} for sign up discount
          </span>
        </div>
        <span className={Styles.rightSide}>
          -${selectedPlan.supDiscountMonthly}/mo.
        </span>
      </div>
    ) : null}

    {selectedPlan.kDiscount ? (
      <div className={Styles.discountInfo}>
        <div className={Styles.leftSide}>
          <Discount />
          <div className={Styles.withSpecialInfo}>
            <span className={Styles.discountTitle}>
              ${selectedPlan.kDiscount} for {selectedPlan.kMonths}{" "}
              {selectedPlan.kMonths === 1 ? "month" : "months"} contract
            </span>
          </div>
        </div>
        <span className={Styles.rightSide}>-${selectedPlan.kDiscount}/mo.</span>
      </div>
    ) : null}

    {selectedPlan.papDiscount ? (
      <div className={Styles.discountInfo}>
        <div className={Styles.leftSide}>
          <Discount />
          <span className={Styles.discountTitle}>
            ${selectedPlan.papDiscount} for Pre-authorized payment
          </span>
        </div>
        <span className={Styles.rightSide}>
          -${selectedPlan.papDiscount}/mo.
        </span>
      </div>
    ) : null}

    {selectedPlan.boxDiscount ? (
      <div className={`${Styles.discountInfo} ${Styles.specialDiscount}`}>
        <div className={Styles.leftSide}>
          <Discount color="var(--primary-color)" />
          <span className={Styles.discountTitle}>
            ${selectedPlan.boxDiscount} PhoneBox special discount
          </span>
        </div>
        <span className={Styles.rightSide}>
          -${selectedPlan.boxDiscount}/mo.
        </span>
      </div>
    ) : null}

    <hr />
    <div className={Styles.discountInfo}>
      <div className={Styles.leftSide}>
        <Discount />
        <span className={Styles.discountTitleBold}>Total Discount</span>
      </div>
      <span className={Styles.rightSideBold}>
        -${selectedPlan.orgPrice - selectedPlan.planPrice}/month
      </span>
    </div>
    <ul>
      <li>Prices are subject to change.</li>
      <li>All listed prices are exclusive of tax.</li>
      <li>
        Promotions are available only to eligible clients. PhoneBox reserves the
        right to reject or revoke any submissions deemed ineligible or
        incorrect, in accordance with our terms of service.
      </li>
      {selectedPlan.serviceNetw.toLowerCase() === "telus" ? (
        <li>
          Through our exclusive partnership with TELUS, your home internet
          service will be fully managed by TELUS, with your invoice,
          communications, and discounts handled directly by their team. For any
          account updates or service inquiries, please contact TELUS customer
          support.
        </li>
      ) : (
        <li>
          Through our exclusive partnership with Telus (Koodo), your home
          internet service will be fully managed by Telus (Koodo), with your
          invoice, communications, and discounts handled directly by their team.
          For any account updates or service inquiries, please contact Koodo
          customer support.
        </li>
      )}
    </ul>
    <hr />
    <div className={Styles.detailsSection}>
      <div className={Styles.detailHeading}>
        <span>+ Details</span>
        <span>
          {selectedPlan.serviceNetw.toLowerCase() === "telus"
            ? "2 years term"
            : "No contract"}
        </span>
      </div>
      <ul>
        <li>
          {selectedPlan.dataCapGB === -1
            ? "Unlimited"
            : selectedPlan.dataCapGB + "GB"}{" "}
          data Usage
        </li>
        <li>Download Speed: Up to {selectedPlan.speedDnload} Mbps</li>
        <li>Upload Speed: Up to {selectedPlan.speedUpload} Mbps</li>
        <li>Modem/WiFi Rental Included</li>
        <li>No Activation Fee</li>
        {selectedPlan.serviceNetw.toLowerCase() === "telus" ? null : (
          <li>No Shipping Fee</li>
        )}
      </ul>
    </div>
    <hr />
  </>
);

export default PlanInfoPart;
