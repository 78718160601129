import React, { useState } from "react";
import Input from "./Input";

import * as Styles from "../../pages/css/home-internet-checkout.module.scss";

const BillingMethodInputs = (props: {
  preferredBillingMethod: string;
  billingPhoneNumber: number;
  useSamePhoneForBilling: boolean;
}) => {

  const [isUsingSameBillingNumber, setIsUsingSameBillingNumber] =
    useState<boolean>(props.useSamePhoneForBilling);

  const [preferredBillingMethod, setPreferredBillingMethod] = useState<
    "Email" | "SMS" | "Email & SMS" | ""
  >(props.preferredBillingMethod as "Email" | "SMS" | "Email & SMS" | "");

  return (
    <>
      <div className={Styles.inputLabel}>
        <span className={Styles.inputSpan}>
          Preferred billing method <sup>*</sup>
        </span>
        <div className={Styles.multipleInputContainer}>
          <label>
            <input
              className={Styles.inputElement}
              type="radio"
              name="preferredBillingMethod"
              value="Email"
              defaultChecked={preferredBillingMethod === "Email"}
              required
              onChange={() => {
                setPreferredBillingMethod("Email");
              }}
            />
            <span className={Styles.innerInputSpan}>Email</span>
          </label>
          <label>
            <input
              className={Styles.inputElement}
              type="radio"
              name="preferredBillingMethod"
              value="SMS"
              defaultChecked={preferredBillingMethod === "SMS"}
              required
              onChange={() => {
                setPreferredBillingMethod("SMS");
              }}
            />
            <span className={Styles.innerInputSpan}>SMS</span>
          </label>
          <label>
            <input
              className={Styles.inputElement}
              type="radio"
              name="preferredBillingMethod"
              value="Email & SMS"
              defaultChecked={preferredBillingMethod === "Email & SMS"}
              required
              onChange={() => {
                setPreferredBillingMethod("Email & SMS");
              }}
            />
            <span className={Styles.innerInputSpan}>Email & SMS</span>
          </label>
        </div>
      </div>
      {preferredBillingMethod === "Email" ||
      preferredBillingMethod === "" ? null : (
        <>
          <div className={Styles.inputLabel}>
            <span className={Styles.inputSpan}>
              Would you like to receive notifications at the same mobile phone
              number? <sup>*</sup>
            </span>
            <div className={Styles.multipleInputContainer}>
              <label>
                <input
                  className={Styles.inputElement}
                  type="radio"
                  name="useSamePhoneForBilling"
                  value="yes"
                  defaultChecked={isUsingSameBillingNumber === true}
                  required
                  onChange={() => {
                    setIsUsingSameBillingNumber(true);
                  }}
                />
                <span className={Styles.innerInputSpan}>Yes</span>
              </label>
              <label>
                <input
                  className={Styles.inputElement}
                  type="radio"
                  name="useSamePhoneForBilling"
                  value="no"
                  defaultChecked={isUsingSameBillingNumber === false}
                  required
                  onChange={() => {
                    setIsUsingSameBillingNumber(false);
                  }}
                />
                <span className={Styles.innerInputSpan}>No</span>
              </label>
            </div>
          </div>
          {isUsingSameBillingNumber === false ? (
            <Input
              title="Valid 10 digit Canadian phone number"
              inputName="billingPhoneNumber"
              isRequired
              inputType="text"
              pattern="\d*"
              defaultValue={
                props.billingPhoneNumber === 0
                  ? ""
                  : props.billingPhoneNumber.toString()
              }
              minLength={10}
              maxLength={10}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default BillingMethodInputs;
