import React from "react";

import * as Styles from "../../pages/css/home-internet-checkout.module.scss";
import { FormInfoFilled, HomeInternetPlan } from "../constant-and-types";

const SummaryView = ({
  formData,
  selectedPlan,
}: {
  formData: FormInfoFilled;
  selectedPlan: HomeInternetPlan;
}) => {
  return (
    <div id={Styles.summaryContainer}>
      <h4>Summary</h4>
      <div className={Styles.infoContainer}>
        <span className={Styles.infoTitle}>Name:</span>
        <span className={Styles.infoValue}>
          {formData.firstName} {formData.lastName}
        </span>
      </div>
      <div className={Styles.infoContainer}>
        <span className={Styles.infoTitle}>Email Address:</span>
        <span className={Styles.infoValue}>{formData.emailAddress}</span>
      </div>
      <div className={Styles.infoContainer}>
        <span className={Styles.infoTitle}>Phone Number:</span>
        <span className={Styles.infoValue}>{formData.phoneNumber}</span>
      </div>
      <div className={Styles.infoContainer}>
        <span className={Styles.infoTitle}>Address:</span>
        <span className={Styles.infoValue}>
          {formData.apartmentOrUnit} {formData.streetAddress}, {formData.city},{" "}
          {formData.province}, {formData.postalCode}
        </span>
      </div>
      <div className={Styles.infoContainer}>
        <span className={Styles.infoTitle}>
          Preferred Installation Date and Time:
        </span>
        <span className={Styles.infoValue}>
          {formData.preferredInstallationDate}{" "}
          {formData.preferredInstallationTime === "08:00:00"
            ? "Morning (8AM-12PM)"
            : formData.preferredInstallationTime === "12:00:00"
            ? "Afternoon (12PM-4PM)"
            : "Anytime"}
        </span>
      </div>
      <div className={Styles.infoContainer}>
        <span className={Styles.infoTitle}>Date of Birth:</span>
        <span className={Styles.infoValue}>{formData.dateOfBirth}</span>
      </div>
      {formData.noInternetAtCurrentLocation ? (
        <div className={Styles.infoContainer}>
          <span className={Styles.infoTitle}>
            Have Internet at Current Location:
          </span>
          <span className={Styles.infoValue}>No</span>
        </div>
      ) : (
        <div className={Styles.infoContainer}>
          <span className={Styles.infoTitle}>Current Service Provider:</span>
          <span className={Styles.infoValue}>
            {formData.currentServiceProvider}
          </span>
        </div>
      )}
      <div className={Styles.infoContainer}>
        <span className={Styles.infoTitle}>Preferred Language:</span>
        <span className={Styles.infoValue}>{formData.preferredLanguage}</span>
      </div>
      {selectedPlan.serviceNetw.toLowerCase() !== "telus" ? null : (
        <>
          <div className={Styles.infoContainer}>
            <span className={Styles.infoTitle}>Preferred Billing Method:</span>
            <span className={Styles.infoValue}>
              {formData.preferredBillingMethod}
            </span>
          </div>
          {formData.receiveBillAtSameAddress ? null : (
            <div className={Styles.infoContainer}>
              <span className={Styles.infoTitle}>Billing Address:</span>
              <span className={Styles.infoValue}>
                {formData.billingApartmentOrUnit}{" "}
                {formData.billingStreetAddress}, {formData.billingCity},{" "}
                {formData.billingProvince}, {formData.billingPostalCode}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SummaryView;
