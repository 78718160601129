import React from "react";
import { navigate } from "gatsby";
import Close from "../../home-internet-content/icons/Close";
import RightArrow from "../../home-internet-content/icons/RightArrow";
import { useDispatch } from "react-redux";
import {
  updateAddress,
  updatePromoCode,
  updateSelectedPlan,
  updateSelectedPlanId,
} from "../../redux/home-internet/homeInternetActions";

import * as Styles from "../../pages/css/home-internet-checkout.module.scss";

const ThankYouPopUp = (props: { isExistingCustomer: boolean }) => {
  const dispatch = useDispatch();

  return (
    <div id={Styles.thankYouPopUpContainer}>
      <div className={Styles.thankYouPopUpContentContainer}>
        <div
          className={Styles.closeButtonContainer}
          onClick={() => {
            dispatch(updateAddress(null) as any);
            dispatch(updatePromoCode("") as any);
            dispatch(updateSelectedPlanId(0) as any);
            dispatch(updateSelectedPlan(null) as any);
          }}
        >
          <Close />
        </div>
        <div className={Styles.thankYouPopUpContent}>
          <h4>Thank you for choosing our Home Internet service!</h4>
          <p>
            We&apos;re excited to confirm that your order has been successfully
            submitted. We will reach out to you shortly to confirm the details
            and ensure everything is set up perfectly for you.
          </p>
          <img
            src="/images/home-internet/thank-you-gift.png"
            alt="Image of Gift with gold coins on side and in air"
          />
          <div className={Styles.innerContainer}>
            {props.isExistingCustomer ? (
              <>
                <h5 className={Styles.existingCustomer}>
                  🎉 You've unlocked a big discount on your mobile plan! 🎉
                </h5>
                <p className={Styles.existingCustomer}>
                  Please allow a moment for the discount to reflect on your
                  plan. <br />
                  Enjoy your savings!
                </p>
                <small>
                  * Once your order has been successfully submitted, we will
                  promptly inform you of the discount price.
                </small>
              </>
            ) : (
              <>
                <h5>🔥 Bonus Deal! 🔥</h5>
                <p>
                  You&apos;ve unlocked a Bundle Discount on your plan with
                  PhoneBox! Check your inbox now for an exclusive discount
                  promotion code just for you !
                </p>
              </>
            )}
          </div>
          {props.isExistingCustomer ? null : (
            <button
              onClick={() => {
                // First Go to Home Internet then go to Plans page to replace checkout page from state
                dispatch(updateAddress(null) as any);
                dispatch(updatePromoCode("") as any);
                dispatch(updateSelectedPlanId(0) as any);
                dispatch(updateSelectedPlan(null) as any);
                setTimeout(() => {
                  navigate("/plans");
                }, 100);
              }}
            >
              Check Out Mobile Plans Now &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <RightArrow />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ThankYouPopUp;
