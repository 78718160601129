import React from "react";
import Close from "../../home-internet-content/icons/Close";

import * as Styles from "../../pages/css/home-internet-checkout.module.scss";

const ConsentPopUp = (props: { closeConsentPopUp: () => void }) => {
  return (
    <div id={Styles.consentPopUpContainer}>
      <div className={Styles.consentPopUpContentContainer}>
        <div
          className={Styles.closeButtonContainer}
          onClick={() => {
            props.closeConsentPopUp();
          }}
        >
          <Close color="#000" />
        </div>
        <div className={Styles.consentPopUpContent}>
          <h4>Consent Agreement</h4>
          <p>
            By proceeding, you agree to allow PhoneBox to activate home internet
            service powered by TELUS. You will receive billing and
            communications directly from TELUS on your new home internet
            service. For any account updates or changes, you will need to
            coordinate directly with TELUS. Your personal information will be
            used exclusively for processing your purchase, managing your
            service, and providing relevant promotions. We are committed to
            safeguarding your privacy and will not share your information with
            third parties or use it for any purposes beyond delivering the
            requested services.
          </p>
          <p>
            We value your privacy and want to ensure you're informed about how
            we use your information. By providing your consent below, you agree
            to receive promotional communications from PhoneBox, including
            special offers, updates, and other relevant materials.
          </p>
          <p>
            You can withdraw your consent at any time by clicking the
            "unsubscribe" link in our emails or contacting us at
            services@gophonebox.com.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConsentPopUp;
